import { useState } from 'react'
import { Tab } from '@headlessui/react'
import { marked } from 'marked';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FounderStories() {
  const [selectedIndex, setSelectedIndex] = useState(0)
  let [stories] = useState({
    TileDB:
    {
        id: 1,
        name: 'TileDB',
        logo: 'tiledb',
        image: 'tiledb2',
        imageAlt: 'TileDB founder Stavros Papadopoulos during an interview',
        content: 'TileDB, born at MIT, is on a mission to transform data management. Giants like Lockheed Martin, NTT Docomo, Amgen, and the Chan Zuckerberg Initiative now harness TileDB\'s power to manage diverse data types effortlessly in a unified, multi-dimensional database.\n\nBacked by industry titans: Amgen, Lockheed Martin, Verizon Ventures.',
        logos: 'tiledb2',
        link: 'https://www.tiledb.io/',
        linkText: 'TileDB'
      },
    Orfium:
      {
        id: 2,
        name: 'Orfium',
        logo: 'orfium',
        image: 'orfium',
        imageAlt: 'The Orfium team',
        content: 'Orfium’s revolutionary platform utilizes AI algorithms to empower artists and rights-holders to protect their creations, ensuring every note is rightfully compensated for. \n\nOrfium is trusted by leading publishers and renowned record labels such as  Warner Chappell, Sony/ATV, EMI, Warner Music Group, Sony Music Entertainment, and Red Bull Records.',
        logos: 'orfium',
        link: 'https://orfium.com/',
        linkText: 'Orfium'
      },
    Anodyne:
      {
        id: 3,
        name: 'Anodyne Nanotech',
        logo: 'anodyne',
        image: 'anodyne2',
        imageAlt: 'Jake Lombardo, Anodyne\'s co-founder, during a presentation',
        content: 'Anodyne Nanotech is reshaping medicine delivery. Their revolutionary Hero Patch ensures efficient and painless delivery of vital drugs and everyday remedies using microneedles.\n\nBy ensuring an accessible, painless experience, Anodyne is transforming healthcare, making it approachable for all.',
        logos: '',
        link: 'https://theheropatch.com/',
        linkText: 'Anodyne'
      },
  })




  let numStories = Object.keys(stories).length;
  return (
    <div className="w-full">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>

        <h2 className="w-full uppercase tracking-widest font-medium text-bigpi-secondary-400 mb-4 lg:mb-8 text-xl inline-flex items-center justify-between border-b-2 pb-4 border-bigpi-primary-500">
          Our companies
          {/* buttons */}
          <Tab.List className="flex gap-3">
            {Object.keys(stories).map((story) => (
              <Tab
                aria-label="Select another company"
                key={story}
                className={({ selected }) =>
                  classNames(
                    'w-4 h-4 pb-2 border-2 border-bigpi-primary-300',
                    'focus:outline-none',
                    selected
                      ? 'border-bigpi-primary-400 bg-bigpi-primary-700'
                      : 'border-bigpi-primary-500 hover:border-bigpi-primary-200 hover:bg-bigpi-primary-700'
                  )
                }
              >
              </Tab>
            ))}
          </Tab.List>

        </h2>
        <div className="pb-2 sm:pb-4 md:pb-4 lg:pb-6 xl:pb-8 relative">


          <Tab.Panels className="">
            {Object.values(stories).map((story, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  '',
                  'focus:outline-none'
                )}
              >

                <div className="flex flex-col lg:flex-row gap-6 md:gap-8">
                  {/* Main image */}
                  <div className="h-full w-full lg:w-[30rem] xl:w-[40rem]">
                    <img alt={story.imageAlt} className="w-[40rem] md:w-full max-w-full aspect-[4/2] md:aspect-[3/2] lg:aspect-auto object-cover clip-corner-70" src={"/assets/stories-"+story.image+".jpg"}/>
                  </div>
                  <div className="w-full flex flex-col sm:justify-between">

                    <h3 className="flex-0 text-bigpi-primary-500 mb-4 flex justify-between items-center">
                      {/* Logo */}
                      <span className="sr-only">{story.name}</span>
                      <img className="max-w-full h-[40px] sm:h-[64px]"
                        alt={'Logo of ' + story.name}
                        src={"/assets/stories-"+story.logo+".svg"}
                        />

                    </h3>

                    {/* Body text */}
                    <div className="flex-1 text-base text-bigpi-primary-100 w-full lg:w-[85%] founder-stories-text" dangerouslySetInnerHTML={{ __html: marked.parse(story.content) }}>
                    </div>
                    <div className="flex-1 flex flex-wrap justify-between items-end mt-4 gap-12 md:gap:0">
                      {/* Company logos */}
                      <div className="text-bigpi-primary-500 flex gap-4 items-center">
                        <img className={classNames(
                              'mx-auto max-w-full',
                              story.logos
                                ? 'block'
                                : 'hidden'
                            )}
                          alt={'Clients of ' + story.name}
                          src={"/assets/stories-logos-"+story.logos+".svg"}
                          />
                      </div>
                      {/* Button */}
                      <div>
                        <a aria-label={'Visit ' + story.linkText} href={story.link} target="_blank" className="whitespace-nowrap uppercase tracking-wider font-medium bg-transparent text-bigpi-primary-200 border-2 border-bigpi-primary-200 hover:border-white hover:bg-bigpi-primary-800 hover:text-white active:border-bigpi-primary-100 active-text-bigpi-primary-100 px-4 py-2 text-sm inline-flex items-center gap-2">
                          Visit {story.linkText}
                          <svg className="w-3 h-3" viewBox="0 0 24 24" version="1.1" fill="currentColor">
                            <path d="M3.90005 1.29999V4.79999H16.7L1.80005 19.7L4.30005 22.2L19.2001 7.29999V20.3H22.7001V1.29999H3.90005Z"/>
                          </svg>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>

              </Tab.Panel>
            ))}
          </Tab.Panels>

          <div className="flex items-center justify-between absolute top-[12%] sm:top-[20%] md:top-[35%] w-full">
            {/* Button left */}
            <button aria-label="Previous company" className="-ml-4 bg-bigpi-primary-600/70 w-[40px] h-[40px] sm:w-[64px] sm:h-[64px] text-bigpi-primary-200 hover:text-white hover:bg-bigpi-primary-800 active:text-bigpi-primary-100 active:bg-bigpi-primary-800" onClick={() => setSelectedIndex(prev => (prev + numStories - 1)%numStories)}>
              <svg className="w-[40px] h-[40px] sm:w-[64px] sm:h-[64px]" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M62 2H2V62H62V2ZM0 0V64H64V0H0Z" />
                <path d="M27.8002 20.9996L29.4562 22.6556L21.9322 30.2156L21.9322 30.5756L46.1602 30.5756L46.1602 33.0236L21.9322 33.0236L21.9322 33.3836L29.4562 40.9436L27.8002 42.5996L17.0002 31.7996L27.8002 20.9996Z" />
              </svg>
            </button>
            {/* Button right */}
            <button aria-label="Next company" className="-mr-4 bg-bigpi-primary-600/70 w-[40px] h-[40px] sm:w-[64px] sm:h-[64px] text-bigpi-primary-200 hover:text-white hover:bg-bigpi-primary-800 active:text-bigpi-primary-100 active:bg-bigpi-primary-800" onClick={() => setSelectedIndex(prev => (prev + 1)%numStories)}>
              <svg className="w-[40px] h-[40px] sm:w-[64px] sm:h-[64px]" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M62 2H2V62H62V2ZM0 0V64H64V0H0Z" />
                <path d="M35.36 42.6L33.704 40.944L41.228 33.384V33.024H17V30.576H41.228V30.216L33.704 22.656L35.36 21L46.16 31.8L35.36 42.6Z" />
              </svg>
            </button>
          </div>

        </div>
      </Tab.Group>
    </div>
  )
}
